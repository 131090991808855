<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2"
                                @click="abrirFiltros" />

                        </div>
                    </template>

                    <template v-slot:end>
                        <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <!-- Tabela de Implantaçoes -->
                <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes" dataKey="id"
                    :paginator="true" :rows="20" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Cockpit Modos de Falha</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column select style="width: 5rem" />

                    <Column field="id" header="id" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">id</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="razao_social" header="Empresa" :sortable="true"
                        headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Empresa</span>
                            {{ slotProps.data.razao_social }}
                        </template>
                    </Column>
                    <Column field="quantidade_arquivos" header="Modo de Falha" :sortable="true"
                        style="text-align: center;" headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Modo de Falha</span>

                            <Tag severity="primary" style="background: red;"
                                v-if="slotProps.data.quantidade_arquivos == 1" value="Layout Não Localizado"></Tag>
                            <Tag severity="primary" style="background: orange;"
                                v-if="slotProps.data.quantidade_arquivos == 7" value="Campos não Extraidos"></Tag>
                            <Tag severity="primary" style="background-color: darkmagenta;"
                                v-if="slotProps.data.quantidade_arquivos == 9" value="Campos não Mapeados"></Tag>
                            <Tag severity="primary" style="background-color: darksalmon;"
                                v-if="slotProps.data.quantidade_arquivos == 8" value="Implantação Divergente"></Tag>


                        </template>
                    </Column>
                    <Column field="status" header="Status" :sortable="true" headerStyle="width:20%; min-width:8rem;">
                        <template #body="">
                            <span class="p-column-title">Status</span>

                            <Tag severity="primary" style="background-color:#999;" value="Aguardando Correção"></Tag>

                        </template>
                    </Column>

                    <Column field="data_fila" header="Data Atualização" :sortable="true"
                        headerStyle="width:30%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Data</span>
                            {{ slotProps.data.data_fila }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:20rem;">
                        <template #body="slotProps">

                            <Button v-if="slotProps.data.quantidade_arquivos == 1" icon="pi pi-pencil" label="Platypus"
                                class="p-button p-button-success mr-2" @click="openPlatypus" />
                            <Button v-if="slotProps.data.quantidade_arquivos == 7" icon="pi pi-pencil" label="Platypus"
                                class="p-button p-button-success mr-2" @click="openPlatypus" />
                            <Button v-if="slotProps.data.quantidade_arquivos == 9" icon="pi pi-pencil" label="Platypus"
                                class="p-button p-button-success mr-2" @click="openPlatypus" />
                            <Button v-if="slotProps.data.quantidade_arquivos == 8" icon="pi pi-pencil"
                                label="Implantação" class="p-button p-button-primary mr-2"
                                @click="openModalEdit(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
                <Dialog v-model:visible="ModalFiltros" :style="{ width: '600px' }" header="Filtros" :modal="true"
                    class="p-fluid">
                    <div class="field">
                        <label for="id_empresa">Modos de Falha</label>
                        <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa"
                            :options="tipos_erros" optionLabel="label" optionValue="value"
                            placeholder="Selecione um tipo"></Dropdown>
                    </div>
                    <div class="field">
                        <label for="unidade">Unidade</label>
                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

                    </div>
                    <div class="field">
                        <label for="mes_referencia">Mês Referencia</label>
                        <Calendar id="mes_referencia" view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia"
                            required="true" autofocus />

                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.ModalFiltros = false" />
                        <Button label="Pesquisar" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="pesquisar()" />
                    </template>
                </Dialog>
                <Dialog v-model:visible="modalEdicao" :style="{ width: '90%', height: '100%' }" header="Correções"
                    :modal="true" class="p-fluid">
                    <div class="grid">
                        <div class="col-6">
                            <iframe src="https://storage.googleapis.com/bignet-arquivos/extracoes/58789/18.pdf"
                                width="100%" style="height: 900px;"></iframe>
                        </div>
                        <div class="col-6">
                            <div class="grid ml-5">
                                <div class="col-2">
                                    <div class="field">
                                        <label for="unidade">Unidade</label>
                                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true"
                                            autofocus />

                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="field">
                                        <label for="unidade">Classe</label>
                                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true"
                                            autofocus />

                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="field">
                                        <label for="unidade">Sub-Classe</label>
                                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true"
                                            autofocus />

                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="field">
                                        <label for="unidade">Mês Referência</label>
                                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true"
                                            autofocus />

                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="field">
                                        <label for="unidade">CNPJ</label>
                                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true"
                                            autofocus />

                                    </div>
                                </div>

                                <div class="col-12">
                                    <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes"
                                        dataKey="id" :paginator="true" :rows="5" :filters="filters"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        :rowsPerPageOptions="[5, 10, 25]"
                                        currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                        responsiveLayout="scroll">
                                        <template #header>
                                            <div
                                                class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                                <h5 class="m-0">Itens Faturados</h5>
                                                <span class=" mt-2 md:mt-0 p-input-icon-left">
                                                    <i class="pi pi-search" />
                                                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                </span>
                                            </div>
                                        </template>

                                        <Column select style="width: 5rem" />

                                        <Column field="id" header="id" :sortable="true"
                                            headerStyle="width:5%; min-width:8rem;">
                                            <template #body="slotProps">
                                                <span class="p-column-title">id</span>
                                                {{ slotProps.data.id }}
                                            </template>
                                        </Column>
                                        <Column field="id" header="CCI" :sortable="true"
                                            headerStyle="width:5%; min-width:8rem;">
                                            <template #body="">
                                                <span class="p-column-title">606</span>
                                                606
                                            </template>
                                        </Column>
                                        <Column field="Descrição" header="Descrição" :sortable="true"
                                           >
                                            <template #body="">
                                                <span class="p-column-title">Descrição</span>
                                                Consumo    
                                            </template>
                                        </Column>
                                        <Column field="Quantidade" header="Quantidade" :sortable="true"
                                            >
                                            <template #body="">
                                                <span class="p-column-title">Quantidade</span>
                                                112    
                                            </template>
                                        </Column>
                                        <Column field="Valor" header="Valor" :sortable="true"
                                            >
                                            <template #body="">
                                                <span class="p-column-title">Valor</span>
                                                230,90    
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>



                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.modalEdicao = false" />
                        <Button label="Salvar Edição" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="this.modalEdicao = false" />
                    </template>
                </Dialog>

            </div>
        </div>
    </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';

export default {
    mixins: [funcoes],
    data() {
        return {
            modalEdicao: false,
            tipos_erros: [
                { label: "Layout não Encontrado", value: 1 },
                { label: "Campos não Mapeados", value: 2 },
                { label: "Campos não Extraidos ", value: 3 },
                { label: "Implantação Divergente", value: 4 },
            ],
            filtros: [],
            ModalFiltros: false,
            faturas: [],
            fatura: {},
            selectImplantacoes: null,
            ModalFatura: false,

            medidor: {},
            modalEditMedidor: false,
            medidorDialog: false,

            itens_faturados: {},
            modalEditItens: false,

            dados_faturas: {},
            modalEditDados: false,

            dados_fiscais: {},
            modalEditDadosFiscais: false,

            dados_tributos: {},
            modalEditDadosTributos: false,

            deleteModalFatura: false,
            deleteMedidorDialog: false,
            deleteDadosDialog: false,
            deleteDadosFiscaisDialog: false,
            deleteDadosTributosDialog: false,
            deleteItensFaturadosDialog: false,
            deleteProductsDialog: false,

            filters: {},
            submitted: false,

            tipos_status: [
                { label: "Processo Iniciado", value: 0 },
                { label: "Separação Iniciada", value: 1 },
                { label: "Separação Finalizada", value: 2 },
                { label: "Extração Iniciada", value: 3 },
                { label: "Extração Finalizada", value: 4 },
                { label: "Implantação Iniciada", value: 5 },
                { label: "Implantação Finalizada", value: 6 },
            ],

            tipos_empresas: [],
            desc_tipos_empresas: [],
            tipo_insumo: [],
            desc_tipo_insumo: [],
            tipos_modalidades: [],
            tipos_concess: [],
            tipos_pagamentos: [],
            tipos_classe_tarifaria: [],
            tipos_subclasse: [],
            tipos_tensao: [],
            tipos_ligacao: [],
            status_array: [
                'Upload Iniciado',
                'Separação Iniciada ',
                'Separação Finalizada',
                'Extração Iniciada',
                'Extração Finalizada',
                'Implantação Iniciada',
                'Implantação Finalizada'
            ]

        };
    },

    created() {
        this.initFilters();
    },
    mounted() {
        this.listarFila();
    },


    methods: {
        openModalEdit(dados) {
            this.modalEdicao = true;
            console.log(dados)
        },
        openPlatypus() {
            window.open('https://platypus.amee.com.br', '_blank');
        },
        listarFila() {
            this.axios
                .post("/faturas/fila/listar")
                .then((response) => {
                    this.faturas = response.data.dados;
                    console.log(this.faturas)
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        openNew() {
            this.fatura = {};
            this.submitted = false;
            this.ModalFatura = true;
        },

        abrirFiltros() {
            this.ModalFiltros = true;
        },

        editarFatura(fatura) {
            this.ModalFatura = true;
            this.fatura = { ...fatura };
        },

        editDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor = { ...medidor };
        },

        editDadosFiscais(dados_fiscais) {
            this.modalEditDadosFiscais = true;
            this.dados_fiscais = { ...dados_fiscais };
        },

        editDadosTributos(dados_tributos) {
            this.modalEditDadosTributos = true;
            this.dados_tributos = { ...dados_tributos };
        },

        editItensFaturados(itens_faturados) {
            this.modalEditItens = true;
            this.itens_faturados = { ...itens_faturados };
        },

        editDadosFatura(dados_faturas) {
            this.modalEditDados = true;
            this.dados_faturas = { ...dados_faturas };
        },

        hideDialog() {
            this.ModalFatura = false;
            this.submitted = false;
        },

        addDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor.id_fatura = medidor.id;
        },

        addItensFaturados(itens_faturados) {
            this.modalEditItens = true;
            this.itens_faturados.id_fatura = itens_faturados.id;
        },


        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};


</script>
